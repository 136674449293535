<template>
  <c-box
    w="100%"
    mx="auto"
    :h="['calc(100vh - 62px)', 'auto']"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['1rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          { label: 'Dashboard', href: '/' },
          { label: 'Keranjang', href: '/cart' },
          { label: 'Checkout', href: '/checkout', isCurrent: true },
        ]"
      />
    </Portal>
    <c-box
      :w="['100%', '600px']"
      mx="auto"
    >
      <c-text
        :d="['none', 'block']"
        font-family="Roboto"
        font-size="20px"
        color="#333"
        font-weight="700"
        mb="1rem"
        align="center"
      >
        Metode Pembayaran
      </c-text>
      <c-flex
        w="100%"
        background="#FDEDCB"
        padding="8px"
        :mb="['1rem', '1.5rem']"
        align-items="center"
        justify-content="center"
      >
        <c-image
          :src="require('@/assets/icons/icon-watch.svg')"
          :width="['20px', '25px']"
          :height="['20px', '25px']"
          :mr="['8px', '16px']"
        />
        <c-text
          font-family="Roboto"
          :font-size="['14px', '16px']"
          font-weight="400"
          color="#111"
        >
          {{ countdown }}
        </c-text>
      </c-flex>

      <c-box
        flex-direction="row"
        justify-content="space-between"
        align-items="center"
        background="#FFFFFF"
        box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
        border-radius="12px"
        :padding="['16px', '24px']"
        :mb="['1rem', '1.5rem']"
      >
        <c-flex width="100%">
          <c-text
            font-family="Roboto"
            :font-size="['16px', '20px']"
            color="#333"
            :font-weight="['500', '700']"
          >
            No. Pesanan
          </c-text>
        </c-flex>
        <c-box
          width="100%"
          border-bottom="1px solid #C4C4C4"
          my="10px"
        />
        <c-text
          font-family="Roboto"
          :font-size="['14px', '16px']"
          color="#333"
          font-weight="400"
        >
          {{ transaction.invoiceNumber }}
        </c-text>
      </c-box>

      <c-box
        flex-direction="row"
        justify-content="space-between"
        align-items="center"
        background="#FFFFFF"
        box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
        border-radius="12px"
        :padding="['0px', '24px']"
        :mb="['1rem', '1.5rem']"
      >
        <c-flex
          width="100%"
          justify-content="space-between"
          align-items="center"
          :mb="['0px', '10px']"
          :p="['16px', '0px']"
        >
          <c-text
            font-family="Roboto"
            :font-size="['16px', '20px']"
            color="#333"
            :font-weight="['500', '700']"
          >
            Detail Pembayaran
          </c-text>
          <c-button
            variant="link"
            font-family="Roboto"
            color="#D32737"
            :font-size="['12px', '16px']"
            font-weight="400"
            as="router-link"
            :to="`/checkout/${transactionId}/payment`"
          >
            Ganti Metode
          </c-button>
        </c-flex>
        <c-box
          :border-top="['1px solid #C4C4C4', 'none']"
          w="100%"
        />
        <c-flex
          w="100%"
          :px="['1rem', '0']"
          my="1rem"
          align-items="center"
        >
          <c-image 
            v-if="transaction?.productPhoto"
            :src="transaction?.productPhoto"
            :w="['70px', '120px']"
            :min-height="['90px', '150px']"
            border-radius="12px"
            flex-shrink="0"
            :mr="['10px', '16px']"
            object-fit="cover"
          />
          <c-flex
            flex-grow="1"
            flex-direction="column"
          >
            <c-text
              font-family="Roboto"
              :font-size="['16px', '28px']"
              :font-weight="[500, 700]"
              color="#333"
            >
              {{ transaction?.productName }} {{ transaction?.productServiceName }} ({{ transaction?.duration ?? '-' }} Hari)
            </c-text>
            <c-box
              v-chakra="{
                ':hover': {
                  textDecoration: 'underline',
                }
              }"
              as="router-link"
              font-family="Roboto"
              :font-size="['12px', '16px']"
              font-weight="400"
              color="#333"
              :to="getHandleMore({
                isKonsultasi: transaction?.productService?.isKonsultasi == 1,
                isExtend: transaction?.productService?.isExtend == 1,
                productId: transaction?.productId,
                productSlug: transaction?.slug,
              })"
            >
              Selengkapnya
            </c-box>
            <c-text
              font-family="Roboto"
              :font-size="['18px', '20px']"
              font-weight="700"
              color="#008C81"
            >
              {{ formatCurrency(transaction?.productPrice) }}
            </c-text>
          </c-flex>
        </c-flex>
        <c-box
          :border-top="['1px solid #C4C4C4', 'none']"
          w="100%"
        />
        <c-box
          v-if="transaction.billerCode"
          :border-top="['none', '1px solid #C4C4C4']"
          p="16px"
        >
          <c-text
            font-family="Roboto"
            :font-size="['16px', '20px']"
            color="#333"
            :font-weight="['500', '700']"
            :mb="['4px', '8px']"
          >
            Kode Perusahaan
          </c-text>
          <c-flex
            align-items="center"
            justify-content="space-between"
          >
            <c-text
              font-family="Roboto"
              :font-size="['14px', '16px']"
              color="#333"
              font-weight="400"
            >
              {{ transaction.billerCode }}
            </c-text>
            <input
              ref="company_code"
              type="hidden"
              :value="transaction.billerCode"
            >
            <c-box
              cursor="pointer"
              @click="copyClipboard('company_code')"
            >
              <c-image
                :src="require('@/assets/icons/icon-copy.svg')"
                :width="['20px', '25px']"
                :height="['20px', '25px']"
              />
            </c-box>
          </c-flex>
        </c-box>
        <c-box
          v-if="transaction.paymentMethodCode != 'gopay'"
          :border-top="['none', '1px solid #C4C4C4']"
          p="16px"
        >
          <c-text
            font-family="Roboto"
            :font-size="['16px', '20px']"
            color="#333"
            :font-weight="['500', '700']"
            :mb="['4px', '8px']"
          >
            No. Virtual Account
          </c-text>
          <c-flex
            align-items="center"
            justify-content="space-between"
          >
            <c-text
              font-family="Roboto"
              :font-size="['14px', '16px']"
              color="#333"
              font-weight="400"
            >
              {{ transaction.vaNumber }}
            </c-text>
            <input
              ref="va_number"
              type="hidden"
              :value="transaction.vaNumber"
            >
            <c-box
              cursor="pointer"
              @click="copyClipboard('va_number')"
            >
              <c-image
                :src="require('@/assets/icons/icon-copy.svg')"
                :width="['20px', '25px']"
                :height="['20px', '25px']"
              />
            </c-box>
          </c-flex>
        </c-box>
        <c-box
          :border-top="['none', '1px solid #C4C4C4']"
          p="16px"
        >
          <c-text
            font-family="Roboto"
            :font-size="['16px', '20px']"
            color="#333"
            :font-weight="['500', '700']"
            :mb="['4px', '8px']"
          >
            Melalui {{ transaction.paymentMethodCode != 'gopay' ? 'Virtual' : '' }} Account
          </c-text>
          <c-flex
            align-items="center"
            justify-content="space-between"
          >
            <c-text
              font-family="Roboto"
              :font-size="['14px', '16px']"
              color="#333"
              font-weight="400"
            >
              {{ transaction.paymentMethodName }}
            </c-text>
            <c-image
              v-if="transaction.image"
              :src="transaction.image"
              :height="['25px', '35px']"
            />
          </c-flex>
        </c-box>
        <c-box
          :border-top="['none', '1px solid #C4C4C4']"
          p="16px"
        >
          <c-text
            font-family="Roboto"
            :font-size="['16px', '20px']"
            color="#333"
            :font-weight="['500', '700']"
            :mb="['4px', '8px']"
          >
            Total Pembayaran
          </c-text>
          <c-flex
            align-items="center"
            justify-content="space-between"
          >
            <c-text
              font-family="Roboto"
              :font-size="['18px', '28px']"
              color="#008C81"
              font-weight="700"
            >
              {{ getTotal }}
            </c-text>
            <input
              ref="total"
              type="hidden"
              :value="transaction.total"
            >
            <c-box
              cursor="pointer"
              @click="copyClipboard('total')"
            >
              <c-image
                :src="require('@/assets/icons/icon-copy.svg')"
                :width="['20px', '25px']"
                :height="['20px', '25px']"
                cursor="pointer"
              />
            </c-box>
          </c-flex>
        </c-box>
      </c-box>

      <c-box
        flex-direction="row"
        justify-content="space-between"
        align-items="center"
        background="#FFFFFF"
        box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
        border-radius="12px"
        :padding="['0px', '24px']"
        :mb="['1rem', '1.5rem']"
      >
        <c-flex
          width="100%"
          :mb="['0px', '10px']"
          :p="['16px', '0px']"
        >
          <c-text
            font-family="Roboto"
            :font-size="['16px', '20px']"
            color="#333"
            :font-weight="['500', '700']"
          >
            Langkah Pembayaran
          </c-text>
        </c-flex>
        <c-box
          v-if="instruction.length > 0"
          border-top="1px solid #C4C4C4"
          px="16px"
          pt="16px"
          :pb="['16px', '0px']"
        >
          <c-accordion :allow-toggle="true">
            <c-accordion-item
              v-for="(item, i) in instruction"
              :key="i"
              border="none"
              mb="10px"
            >
              <c-accordion-header
                background-color="#F2F2F2"
                border-radius="8px"
              >
                <c-box
                  flex="1"
                  text-align="left"
                  font-family="Roboto"
                  :font-size="['16px', '20px']"
                  :font-weight="['500', '700']"
                >
                  {{ item.title }}
                </c-box>
                <c-accordion-icon />
              </c-accordion-header>
              <c-accordion-panel
                v-if="item.instruction.length > 0"
                pb="2"
              >
                <c-image
                  v-if="transaction.qrCodeLink && item.type == 'qrcode'"
                  :src="transaction.qrCodeLink"
                  max-width="250px"
                  mx="auto"
                  mb="8px"
                />
                <c-text
                  mb="4px"
                  :font-size="['14px', '16px']"
                >
                  Petunjuk Pembayaran dengan {{ item.title }}
                </c-text>
                <c-list
                  as="ol"
                  style-type="decimal"
                  style-pos="outside"
                  ml="1rem"
                >
                  <c-list-item
                    v-for="(step, index) in item.instruction"
                    :key="index"
                    font-family="Roboto"
                    :font-size="['14px', '16px']"
                    v-html="step"
                  />
                </c-list>
                <c-flex justify-content="center">
                  <c-button
                    v-if="transaction.deepLink && item.type == 'deeplink'"
                    variant="outline"
                    color="#008C81"
                    border-color="#008C81"
                    border-radius="32px"
                    mt="16px"
                    :w="['100%', '400px']"
                    :font-size="['14px', '16px']"
                    as="a"
                    :href="transaction.deepLink"
                    target="_blank"
                    h="50px"
                  >
                    Lanjutkan Pembayaran
                  </c-button>
                </c-flex>
              </c-accordion-panel>
            </c-accordion-item>
          </c-accordion>
        </c-box>
      </c-box>

      <c-box
        w="100%"
        text-align="center"
        :pb="['16px', '0px']"
      >
        <c-button
          background="#008C81"
          color="#FFF"
          py="10px"
          border-radius="60px"
          font-size="16px"
          font-weight="500"
          font-family="Roboto"
          :w="['100%', '400px']"
          h="50px"
          :is-loading="loading"
          loading-text="Memperbarui Pembayaran"
          @click="checkPayment"
        >
          Cek Pembayaran
        </c-button>
      </c-box>
    </c-box>

    <ModalError
      :is-open="isOpenPaymentNotDone"
      :image="require('@/assets/images/illustration-8-raw.jpg')"
      title="Pembayaran belum diterima"
      note="Pembayaranmu belum diterima, silahkan lakukan pembayaran. Jika kamu sudah melakukan pembayaran silahkan cek sekala berkala"
      button-text="Kembali"
      :button-icon="false"
      :button-action="() => {
        handleClosePaymentNotDone()
      }"
      @close="handleClosePaymentNotDone"
    />
  </c-box>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { mapActions, mapGetters } from 'vuex'
import { formatCurrency } from '@/utils/format-currency'
import ModalError from '@/components/widgets/modal-error.vue'

export default {
  name: 'CheckoutDetail',
  components: {
    BreadcrumbPath,
    ModalError,
  },
  data() {
    return {
      transactionId: this.$route.params.id,
      countdown: 'Selesaikan pembayaran dalam ',
      isExpired: false,
      instruction: [],
      isOpenPaymentNotDone: false,
      loading: false,
      initInterval: null,
      countdownInterval: null,
    }
  },
  computed: {
    ...mapGetters({
      transaction: 'checkout/transaction',
    }),
    _seconds() {
      return 1000
    },
    _minutes() {
      return 1000 * 60
    },
    _hours() {
      return 60000 * 60
    },
    _days() {
      return 3600000 * 24
    },
    getTotal() {
      return this.transaction.total ? formatCurrency(this.transaction.total) : '-'
    },
  },
  async created() {
    await this.init()
    if (this.transaction.deepLink && this.$isMobile() && this.transaction.paymentInstructions.some(el => el.type == 'deeplink')) {
      window.open(this.transaction.deepLink)
    }
    if (this.transaction.status == 'pending') {
      this.initInterval = setInterval(() => {
        this.init()
      }, 5000)
    }
  },
  beforeDestroy() {
    this.destoryAllInterval()
  },
  methods: {
    formatCurrency,
    ...mapActions({
      getTransaction: 'checkout/getTransaction',
    }),
    getHandleMore({ isKonsultasi, isExtend, productId, productSlug }) {
      if (isKonsultasi) {
        return {
          name: 'client.program.consultation',
        }
      }
      if (isExtend) {
        return {
          name: 'client.program.extend',
        }
      }
      return {
        name: 'client.program.detail',
        params: { id: productSlug ? productSlug : productId },
      }
    },
    async init() {
      await this.getTransaction(this.transactionId)
        .then((res) => {
          if (_.isEmpty(res.data)) {
            this.$toast({
              status: 'warning',
              title: 'Peringatan',
              description: 'Transaksi tidak ditemukan',
              duration: 2000,
            })
            this.$router.push('/')
            return
          }
          if (res.data.status == 'done') {
            window.fbq('track', 'PaymentSuccess')
            this.$toast({
              status: 'success',
              title: 'Berhasil',
              description: 'Transaksi sudah selesai.',
              duration: 2000,
            })
            if (res.data?.isChooseNutritionist == 0) {
              this.$router.push({
                name: 'client.checkout.done',
                query: { 
                  transaction_id: this.transactionId,
                  program_id: res.data?.programId,
                  choose_nutri: res.data?.isChooseNutritionist,
                },
              }).catch()
              return
            }
            this.$router.push({
              name: 'client.checkout.done',
              query: { transaction_id: this.transactionId },
            }).catch()
            return
          } else if (res.data.status == 'failed') {
            this.$toast({
              status: 'error',
              title: 'Peringatan',
              description: 'Transaksi tidak dapat dilanjutkan.',
              duration: 2000,
            })
            this.$router.push({
              name: 'client.profile.historyTransaction',
            })
            return
          }
        })
        .catch((e) => {
          this.destoryAllInterval()
          this.$toast({
            status: 'error',
            title: 'Error',
            description: e?.message ? e?.message : (e?.data.message ? e?.data.message : 'Transaksi tidak ditemukan'),
            duration: 3000,
          })
          this.$router.push('/')
          return
        })

      if (_.isNull(this.transaction.paymentMethodId)) {
        this.$router.push(`/checkout/${this.transaction.id}/payment`)
        this.$toast({
          status: 'warning',
          title: 'Peringatan',
          description: 'Silahkan pilih metode pembayaran',
          duration: 2000,
        })
        return
      }
      if (this.transaction.paymentInstructions && this.transaction.paymentInstructions.length > 0) {
        let instructions = this.transaction.paymentInstructions
        const isDeepLink = instructions.some(el => el.type == 'deeplink')
        if (!this.$isMobile() && isDeepLink) {
          instructions = instructions.filter(el => el.type != 'deeplink')
        }
        const jsonString = JSON.stringify(instructions)
        const replacedJsonString = jsonString
          .replace(/\W{}/g, '')
          .replace(/{{va_number}}/g, this.transaction.vaNumber)
          .replace(/{{kode_perusahaan}}/g, this.transaction.billerCode)
        this.instruction = JSON.parse(replacedJsonString)
      }
      this.showCountdown()
    },
    showCountdown() {
      const utc = moment(this.transaction.expiryAt).subtract(7, 'h').format('YYYY-MM-DD[T]HH:mm:ss[Z]')
      const end = new Date(utc)
      this.countdownInterval = setInterval(() => {
        const now = new Date()
        const distance = end.getTime() - now.getTime()
        if (distance < 0) {
          this.isExpired = true
          this.destoryAllInterval()
          this.countdown = 'Melebihi batas waktu pembayaran'
          this.$toast({
            status: 'warning',
            title: 'Ooops...',
            description: 'Waktu pembayaran habis. Silakan melakukan pembelian ulang.',
            duration: 4000,
          })
          this.$router.push({
            name: 'client.profile.historyTransaction',
          })
          return
        }
        // const days = Math.floor(distance / this._days)
        const hours = Math.floor(distance / this._hours)
        const minutes = Math.floor((distance % this._hours) / this._minutes)
        const seconds = Math.floor((distance % this._minutes) / this._seconds)
        const displayMinutes = minutes < 10 ? '0' + minutes : minutes
        const displaySeconds = seconds < 10 ? '0' + seconds : seconds
        const displayHours = hours < 10 ? '0' + hours : hours
        // const displayDays = days < 10 ? 0 + days : days
        this.countdown = 'Selesaikan pembayaran dalam ' + displayHours + ':' + displayMinutes + ':' + displaySeconds
      }, 1000)  
    },
    copyClipboard(ref) {
      const el = this.$refs[ref]
      try {
        el.setAttribute('type', 'text')
        el.select()
        document.execCommand('copy')
        this.$toast({
          status: 'success',
          title: 'Success',
          description: 'Berhasil disalin',
          duration: 2000,
        })
      } catch (err) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: 'Oops, unable to copy',
          duration: 2000,
        })
      }
      el.setAttribute('type', 'hidden')
    },
    async checkPayment() {
      this.loading = true
      await this.init()
      if (this.transaction.status == 'pending') {
        this.loading = false
        this.isOpenPaymentNotDone = true
      }
    },
    handleClosePaymentNotDone() {
      this.isOpenPaymentNotDone = false
    },
    destoryAllInterval() {
      clearInterval(this.initInterval)
      clearInterval(this.countdownInterval)
    },
  },
}
</script>
